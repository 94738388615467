import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Modal, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { myroutes } from "../../../routes/webroute";
import { RadioButton } from "primereact/radiobutton";
import "react-phone-number-input/style.css";
import ObjetsService from "../../../services/ObjetsService";
import CalendriersService from "../../../services/CalendriersService";
import ProceduresService from "../../../services/ProceduresService";
import TypesDocumentsService from "../../../services/TypesDocumentsService";
import ModesPaiementsService from "../../../services/ModesPaiementsService";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

import {
  afficherErrorToast,
  afficherMessagesErreur,
  afficherMessagesSuccess,
  afficherOneMessageError,
  checkIsAutreProcedureHelper,
  checkProcedureHelper,
  convertDateToTexte,
  getPaiementConfig,
  initiateFedaPayCheckout,
} from "../../../utils/helpers";
import {
  resetDossierFormulaire,
  setRenseignementAffaire,
} from "../../../app/providers/authSlice";
import DossiersService from "../../../services/DossiersService";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";
import { ProgressSpinner } from "primereact/progressspinner";
import Swal from "sweetalert2";
import LayoutActeur from "../inc/layout_acteur";
import AppelanteFormContainer from "../../../components/AppelanteFormContainer";
import PaiementsService from "../../../services/PaiementsService";
import IntimeFormContainer from "../../../components/IntimeFormContainer";
import ConfirmationModal from "../../../components/ConfirmationModalComponents";
import TypesproceduresService from "../../../services/TypesproceduresService";
import TribunauxService from "../../../services/TribunauxService";
import AssujettiesService from "../../../services/AssujettiesService";
import { Dropdown } from "primereact/dropdown";
import JoursFeriesService from "../../../services/JoursFeriesService";
import PaymentHandler from "../../../lib/PaymentHandler";
import { PaiementDossierStore } from "../../../lib/PaiementDossierStore";

const schema = Joi.object({
  objet_id: Joi.string().required().messages({
    "string.empty": "Veuillez sélectionner un objet.",
  }),
  jugement_attaque: Joi.string().min(3).required().messages({
    "string.empty": "Veuillez resnseigner le jugement attaqué.",
    "string.min": "Le jugement attaqué doit contenir au moins 3 caractères.",
    "any.required": "Le jugement attaqué est obligatoire.",
  }),
  date_jugement: Joi.date().required().messages({
    "date.base": "La date du jugement doit être une date valide.",
    "any.required": "La date du jugement est obligatoire.",
  }),
  provenance: Joi.any().required().messages({
    "any.empty": "Veuillez sélectionner une provenance.",
    "any.required": "La provenance de l'affaire est obligatoire",
  }),
  type_procedure_id: Joi.any().required().messages({
    "any.required": "Veuillez sélectionner un type de procédure.",
  }),
  procedure_id: Joi.number().required().messages({
    "any.required": "La procédure est obligatoire.",
  }),
  calendrier_id: Joi.any().required().messages({
    "any.required": "La date d'audience est obligatoire.",
  }),
  showNumeroDossier: Joi.boolean()
    .valid(true, false) // Accepte uniquement true ou false
    .required() // Assurez-vous que la valeur soit présente
    .messages({
      "any.required": "La visibilité du numéro de dossier doit être spécifiée.",
      //"boolean.base": "La valeur de showNumeroDossier doit être un booléen (true ou false).",
      //"any.only": "La valeur de showNumeroDossier doit être soit true, soit false."
    }),
  reference_dossier: Joi.string().when("showNumeroDossier", {
    is: true, // Condition : si showNumeroDossier == true
    then: Joi.required().messages({
      "any.required":
        "La référence du dossier est obligatoire pour cette procédure.",
    }),
    otherwise: Joi.optional(), // Pas obligatoire pour les autres valeurs de procedure_id
  }),
});

export default function CreerDossiersActeurs() {
  const token = useSelector((state) => state.auth.token);
  const [checked, setChecked] = useState(false);
  const [objets, setObjets] = useState([]);
  const [objet_id, setObjet_id] = useState("");
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(getFormattedDate());
  const [paths, setPaths] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [calendriers, setCalendriers] = useState([]);
  const [calendrier_id, setCalendrier_id] = useState("");
  //const [errors, setErrors] = useState({});
  const [procedures, setProcedures] = useState([]);
  const [proceduresData, setProceduresData] = useState([]);
  const [typesdocuments, setTypesdocuments] = useState([]);
  const [type_document_id, setTypedocument_id] = useState("");
  const [dataIsVerified, setDataIsVerified] = useState(true);
  const [modespaiements, setModesPaiements] = useState([]);
  const [modepaiement_id, setModePaiementId] = useState("");
  const [montant_payer, setMontantPayer] = useState(10000);
  const [loading, setLoading] = useState(false); // État pour le chargement
  const [calendriers_available, setCalendriersAvailable] = useState([]); //Toutes les dates disponibles pour une procédure

  const [showNumeroDossier, setShowNumeroDossier] = useState(false);
  const [isAutreProcedure, setIsAutreProcedure] = useState(false);
  const [allCalendriers, setAllCalendriers] = useState([]);
  const [libelleTribunal, setLibelleTribunal] = useState("");
  const [validationsErrors, setValidationsErrors] = useState([]);

  const [tribunalSelected, setTribunalSelected] = useState("");
  const [joursferies, setJoursferies] = useState([]);

  const dispatch = useDispatch();
  const renseignement_affaire = useSelector(
    (state) => state.auth.renseignementAffaire
  );

  const appelantSaisie = useSelector((state) => state.auth.appelants);
  const intimeSaisie = useSelector((state) => state.auth.intimes);
  const auth = useSelector((state) => state.auth);
  const [all_types_procedures, setAllTypesprocedures] = useState([]);
  const [documents, setDocuments] = useState([{ id: 1 }]);
  const [docData, setDocData] = useState(
    documents.map((doc) => ({ id: doc.id, type_document_id: "", file: null }))
  );

  const [tribunaux, setTribunaux] = useState([]);
  //const [liste_tribunaux, setListeTribunaux] = useState([]) //Cette liste sera afficher dans le dropdown

  const [assujetties_acteurs, setAssujetties_acteurs] = useState([]);

  //declaration des variables pour le form d'enregistrement
  const [addFormData, setAddFormData] = useState(renseignement_affaire);

  const addDocument = () => {
    //const newDocument = {id: documents.length + 1};
    //setDocuments([...documents, newDocument]);

    const newDocId = docData.length ? docData[docData.length - 1].id + 1 : 1;
    setDocData([
      ...docData,
      { id: newDocId, type_document_id: "", file: null },
    ]);
  };

  const removeDocument = (docId) => {
    setDocData((prevDocData) => prevDocData.filter((doc) => doc.id !== docId));
  };

  const handleChange = async (e) => {
    if (e.target.name == "provenance") {
      let getData = tribunaux.find(
        (option) => parseInt(option.id) == parseInt(e.value.id)
      );

      setLibelleTribunal(getData ? getData.name : "");
      setAddFormData({ ...addFormData, [e.target.name]: e.value });
      //setTribunalSelected(e.value)
      //console.log(e.value.id)
    } else {
      setAddFormData({ ...addFormData, [e.target.name]: e.target.value });
    }
    //Si c'est un DEP et la reference_dossier est renseigné, récupérer le calendrier de la chambre où se situe le dossier
    /*if (e.target.name === "reference_dossier" && e.target.value.length < 5) {
      setCalendriers([])
    }*/

    if (e.target.name == "procedure_id") {
      checkProcedure(e.target.value);

      if (addFormData.type_procedure_id == 1) {
        //PROCÉDURE AU FOND
        if (checkIsAutreProcedure(e.target.value)) {
          // Filtrer les données pour obtenir uniquement celles dont le code est identique à celui du type de procédure
          let filteredData = allCalendriers.filter(
            (item) =>
              item.get_chambre.get_pole.get_type_procedure?.code === "PF" &&
              item.get_chambre.code === "1CP1"
          );
          //console.log(filteredData)

          // Mettre à jour le state des calendriers avec les données filtrées
          setCalendriers(filteredData);
        } else {
          // Filtrer les données pour obtenir uniquement celles dont le code est identique à celui du type de procédure
          let filteredData = calendriers_available.filter(
            (item) =>
              item.get_chambre.get_pole.get_type_procedure?.code === "PF" &&
              item.get_chambre.code === "1CP1"
          );
          //console.log(filteredData)

          // Mettre à jour le state des calendriers avec les données filtrées
          setCalendriers(filteredData);
        }
      } else if (addFormData.type_procedure_id == 2) {
        //PROCÉDURE PRÉSIDENTIELLE
        let filteredData = allCalendriers.filter(
          (item) =>
            item.get_chambre.get_pole.get_type_procedure?.code === "PP" &&
            item.get_chambre.code === "3CP6"
        );
        //console.log(filteredData)

        // Mettre à jour le state des calendriers avec les données filtrées
        setCalendriers(filteredData);
      }
      //console.log(isAutreProcedure)
    }
  };

  //Gestion des types documents sélectionnés
  const handleChangeTypeDoc = (e, docId) => {
    const newDoc = parseInt(e.target.value);

    //const { value } = e.target;
    /* setSelectedTypes((prevTypes) => ({
         ...prevTypes,
         [docId]: newDoc
     }));*/

    setDocData((prevDocData) =>
      prevDocData.map((doc) =>
        doc.id === docId ? { ...doc, type_document_id: newDoc } : doc
      )
    );
  };

  const handleFindDatesAudiences = async () => {
    //Si c'est un DEP et la reference_dossier est renseigné, récupérer le calendrier de la chambre où se situe le dossier
    if (addFormData.reference_dossier.length > 5) {
      // Formater le reference_dossier en remplaçant les "/" par "_"
      let formattedReferenceDossier = addFormData.reference_dossier.replace(
        /\//g,
        "_"
      );

      await DossiersService.findByReference(
        formattedReferenceDossier,
        token
      ).then((response) => {
        if (response.status == 200 && response.data.data) {
          // Filtrer les données pour obtenir uniquement celles dont le code est identique à celui du type de procédure
          let filteredData = calendriers_available.filter(
            (item) =>
              parseInt(item.chambre_id) === response.data.data.last_chambre_id
          );
          //console.log(filteredData);

          // Mettre à jour le state des calendriers avec les données filtrées
          setCalendriers(filteredData);
        } else {
          setCalendriers([]);
        }
      });
    }
  };

  const handleConfirmEnregistrement = () => {
    // Logique d'enregistrement des données
    setShowModal(false); // Fermer le modal après l'enregistrement
    handleSaveEnregistrement(); //Appel de la fonction définitive d'enregistrement des données
  };

  const handleCancelEnregistrement = () => {
    setShowModal(false); // Fermer le modal
  };

  // Fonction pour formater la date au format requis
  function getFormattedDate() {
    const date = new Date();
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ajoute un zéro devant si nécessaire
    let day = date.getDate().toString().padStart(2, "0"); // Ajoute un zéro devant si nécessaire
    return `${year}-${month}-${day}`; // Retourne la date au format YYYY-MM-DD
  }

  const handleFileChange = (e, docId) => {
    const selectedFile = e.target.files[0];

    setDocData((prevDocData) =>
      prevDocData.map((doc) =>
        doc.id === docId ? { ...doc, file: selectedFile } : doc
      )
    );
  };

  const handleRemoveFile = (fileName) => {
    const updatedPaths = paths.filter((file) => file.name !== fileName);
    setPaths(updatedPaths);
  };

  const handleChangeTypeprocedure = (e) => {
    setAddFormData((prevState) => ({
      ...prevState,
      type_procedure_id: parseInt(e.value),
    }));
    // Filtrer les données pour obtenir uniquement celles dont le code est identique à celui du type de procédure
    let filteredData = calendriers_available.filter(
      (item) =>
        item.get_chambre.get_pole?.get_type_procedure?.code === e.target.name
    );

    // Mettre à jour le state des calendriers avec les données filtrées
    setCalendriers(filteredData);

    //Afficher les procédures selon le type procédure sélectionné
    const data = proceduresData.filter(
      (proc) => proc.type_procedure_id === parseInt(e.value)
    );
    setProcedures(data);
    //Reset procedure_id
    setAddFormData((prevState) => ({
      ...prevState,
      procedure_id: "",
    }));
  };

  //get liste objets
  const fetchObjets = async () => {
    await ObjetsService.index(token)
      .then((response) => {
        setObjets(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste calendriers
  const fetchCalendriers = async () => {
    try {
      const response = await CalendriersService.index(token);
      const responseData = response.data;

      if (
        responseData.status &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        //Filtrer pour récupérer les dates qui sont à 7j au moins de la date du jour
        const currentDate = new Date();

        const filteredDates = responseData.data.filter((dateString) => {
          const date = new Date(dateString.jour);
          const differenceInTime = date.getTime() - currentDate.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);
          return differenceInDays >= 7;
        });

        // Trier les dates par ordre croissant
        const sortedDates = filteredDates.sort((a, b) => {
          const dateA = new Date(a.jour);
          const dateB = new Date(b.jour);
          return dateA - dateB;
        });

        setCalendriersAvailable(sortedDates);

        ///###Recupérer toutes les dates pour Autres procédures en appel ####
        // Supposons que currentDate soit défini à la date actuelle sans l'heure
        currentDate.setHours(0, 0, 0, 0);

        const allDates = responseData.data.filter((dateString) => {
          const date = new Date(dateString.jour);
          date.setHours(0, 0, 0, 0); // Pour comparer uniquement les dates sans l'heure
          return date >= currentDate;
        });

        const sortedAllDates = allDates.sort((a, b) => {
          const dateA = new Date(a.jour);
          const dateB = new Date(b.jour);
          return dateA - dateB;
        });

        setAllCalendriers(sortedAllDates);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  };

  //get liste procedure
  const fetchProcedures = async () => {
    await ProceduresService.index(token)
      .then((response) => {
        const data = response.data.data;
        // Définir l'ordre
        const order = {
          "ACTE D'APPEL AVEC ASSIGNATION": 1,
          "AVENIR D'AUDIENCE": 2,
          "AUTRES PROCEDURES EN APPEL": 3,
          "REQUETE CONJOINTE": 4,
        };
        // Trier les données selon l'ordre défini
        const sortedData = data.sort((a, b) => {
          return order[a.libelle] - order[b.libelle];
        });
        setProceduresData(sortedData);
        setProcedures(
          sortedData.filter(
            (proc) => proc.type_procedure_id === addFormData.type_procedure_id
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const findLibelleProcedure = (id) => {
    const findData = proceduresData.find((proc) => proc.id === id);

    return findData ? findData.libelle : "";
  };

  //get liste types documents
  const fetchTypesdocuments = async (is_avenir) => {
    await TypesDocumentsService.index(token)
      .then((response) => {
        //Filtrer les données
        const filteredData = response.data.data.filter(
          (option) =>
            option.is_enrolement == true || option.is_enrolement == null
        );

        //Ordonnant par ordre alphabétique
        const sortedData = filteredData.sort((a, b) => {
          const nameA = a.libelle.toUpperCase(); // ignore upper and lowercase
          const nameB = b.libelle.toUpperCase(); // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        setTypesdocuments(sortedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste mode de paiement
  const fetchModepaiements = async () => {
    await ModesPaiementsService.index(token)
      .then((response) => {
        //Récupérer uniquement les modes de paiements actif
        setModesPaiements(
          response.data.data.filter((mode) => mode.statut_id === 1)
        );
        //console.log(response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Save info of section Renseignement sur l'affaire
  const handleSaveProgess = () => {
    // Transmission des données au reducer
    //dispatch(setRenseignementAffaire(addFormData));

    //handleSubmit(onSubmit)(); // Appel manuel de la fonction de validation

    /*afficherMessagesSuccess(
      "Renseignements sur l'affaire enregistré avec succès"
    );*/

    const validation = schema.validate(addFormData, { abortEarly: false });

    if (validation.error) {
      //console.error(validation.error.details);
      // Stocker les messages d'erreurs
      const errorMessages = Object.values(validation.error.details).map(
        (error) => error.message
      );
      setValidationsErrors(errorMessages);
      return;
    }

    // Synchronisation avec Redux après validation
    dispatch(setRenseignementAffaire(addFormData));

    afficherMessagesSuccess(
      "Les renseignements ont été enregistrés avec succès !"
    );
    setValidationsErrors([]);

    //console.log(addFormData)
  };

  // Sauvegarder les informations de la section "Renseignements sur l'affaire"
  /*const handleSaveProgess = async () => {
  dispatch(setRenseignementAffaire(addFormData));
  // Récupération et validation des données
  const isValid = schema.validate(addFormData); // Validation avec Joi

  if (isValid.error) {   
     handleSubmit(onSubmit)() // Appel manuel de la fonction de soumission
    console.error(isValid.error.details); // Affiche les erreurs
  } else {
    //onSubmit(message)
    console.log(addFormData); // Soumet les données
  }
};


  // Transmission des données au reducer
  const onSubmit = () => {
    dispatch(setRenseignementAffaire(addFormData));
  };*/

  //Save formData
  const handleSaveEnregistrement = async () => {
    setDataIsVerified(false);

    let form = {
      appelants: appelantSaisie,
      intimes: intimeSaisie,
      path: docData,
      date_jugement: addFormData.date_jugement,
      type_document_id: addFormData.type_document_id,
      calendrier_id: addFormData.calendrier_id,
      procedure_id: addFormData.procedure_id,
      type_procedure_id: addFormData.type_procedure_id,
      tribunal_id: addFormData.provenance,
      jugement_attaque: addFormData.jugement_attaque,
      objet_id: addFormData.objet_id,
      modepaiement_id,
      montant_payer,
      get_reference_dossier: addFormData.reference_dossier,
    };
    //return false;

    await DossiersService.store(form, token)
      .then((response) => {
        if (response.status === 200) {
          //Reset dossier formulaire
          //dispatch(resetDossierFormulaire())
          if (modepaiement_id.length == 0 || modepaiement_id == "") {
            navigate(myroutes.dossiersacteurs_index);

            Swal.fire({
              icon: "success",
              //title: 'Oups !!!',
              text: "Un mail contenant le lien de paiement vous a été envoyé par mail !",
            });
          }

          //confirm action
          Swal.fire({
            icon: "success",
            title: "Merci ! Vous pouvez procéder au paiement.",
            text: response.data.message,
            showCancelButton: true,
            cancelButtonText: "Payer plus tard",
            confirmButtonText: "Payer maintenant",
            confirmButtonColor: "red",
          }).then((result) => {
            if (result.isConfirmed && modepaiement_id.length > 0) {
              //Afficher le hook de paiement
              try {
                const params = {
                  montant_payer,
                  reference_dossier : response.data.reference_dossier,
                  auth,
                  config: getPaiementConfig(),
                  successHandlerFedaPay,
                  successHandlerTresorPay
                };
                PaymentHandler.executeStrategy(modepaiement_id, params);
              } catch (error) {
                afficherErrorToast(error.message);
              }

              /*if (modepaiement_id == "KKIAPAY") {
                var config = getPaiementConfig();
                openKkiapayWidget({
                  amount: montant_payer,
                  position: "center",
                  data: response.data.reference_dossier,
                  name: auth.user.fullname,
                  theme: "green",
                  api_key: config.key,
                  sandbox: config.isSandbox,
                  email: auth.user.email,
                  phone: parseInt(auth.user.telephone),
                });
              } else {
                //Lancer FedaPay
                initiateFedaPayCheckout({
                  amount: montant_payer,
                  description: response.data.reference_dossier,
                  client: {firstname : auth.user.fullname, email: auth.user.email, phone_number: {number: parseInt(auth.user.telephone), country: "BJ"}},
                  onCompleteCallback: (resp) => {
                    if (resp.reason === "dialog_dismissed") {
                      afficherErrorToast(
                        "Vous avez fermé la fenêtre de paiement"
                      );
                    } else {
                      afficherMessagesSuccess(
                        "Transaction terminée: " + resp.reason
                      );
                    }
                    successHandlerFedaPay(resp.transaction);
                    //console.log(resp.transaction);
                  },
                });
              }*/
            } else {
              //Reset dossier formulaire
              dispatch(resetDossierFormulaire());
              navigate(myroutes.dossiersacteurs_index);

              Swal.fire({
                icon: "success",
                //title: 'Oups !!!',
                text: "Un mail contenant le lien de paiement vous a été envoyé !",
              });
            }
          });
        }
      })
      .catch((error) => {
        setDataIsVerified(true);
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          timer: 3000,
        });
        console.log(error);
      });
  };

  //Gestion paiement FedaPay success
  const successHandlerFedaPay = async (response) => {
    setLoading(true); // Début du chargement

    //Recuperation du code de la transaction de FedaPay
    var code_transaction = response.id;
    // Enregistrer le paiement
    let paiementsInfo = {
      montant: response.amount,
      reference_dossier: response.description,
      telephone: response.payment_method?.number,
      modepaiement_id: modepaiement_id ? modepaiement_id : 'FEDAPAY' ,
    };
    //console.log(paiementsInfo)
    //return false;
    
    //Store le paiement avec les data   
    PaiementDossierStore(code_transaction, paiementsInfo, token,setDataIsVerified, setLoading, resetDossierFormulaire, {isActeur : true})
  };

  //Get kkiapay reponse after paiement success
  const successHandler = async (response) => {
    setLoading(true); // Début du chargement
    //Recuperation du code de la transaction de kkiapay
    var code_transaction = response.transactionId;
    // Enregistrer le paiement
    let paiementsInfo = {
      montant: montant_payer,
      reference_dossier: response.data,
      telephone: auth.user.telephone,
      modepaiement_id: 'KKIAPAY',
    };
    //console.log(paiementsInfo)
    //return false;
    
    //Store le paiement avec les data   
    PaiementDossierStore(code_transaction, paiementsInfo, token,setDataIsVerified, setLoading, resetDossierFormulaire, {isActeur : true})
  };

   //Gestion paiement TresorPay success
   const successHandlerTresorPay = async (response) => {
    setLoading(true); // Début du chargement

    //Recuperation du code de la transaction de FedaPay
    var code_transaction = response.id;
    // Enregistrer le paiement
    let paiementsInfo = {
      montant: response.amount,
      reference_dossier: response.description,
      telephone: response.payment_method?.number,
      modepaiement_id: modepaiement_id ? modepaiement_id : 'TRESORPAY',
      reference: response.reference
    };
    //console.log(paiementsInfo)
   
    //Store le paiement avec les data   
    PaiementDossierStore(code_transaction, paiementsInfo, token,setDataIsVerified, setLoading, resetDossierFormulaire, {isActeur : true})
  };

  //get liste Typesprocedures
  const fetchTypesprocedures = async () => {
    try {
      const response = await TypesproceduresService.index(token);
      const responseData = response.data;

      if (
        responseData.status &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        //console.log(responseData.data);
        setAllTypesprocedures(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de types procedures:", error);
    }
  };

  //Controle sur la procédure sélectionnée pour afficher le champ Numero du dossier
  const checkProcedure = (procedure_id) => {
    let response = checkProcedureHelper(procedure_id, procedures);
    if (response) {
      setShowNumeroDossier(true);
      setAddFormData((prevData) => ({
        ...prevData,
        showNumeroDossier: true, // Maintien des autres valeurs intactes
      }));
    } else {
      setShowNumeroDossier(false);
      setAddFormData((prevData) => ({
        ...prevData,
        showNumeroDossier: false, // Maintien des autres valeurs intactes
      }));
    }
  };

  //Verifie s'il s'agit de AUTRES PROCEDURES EN APPEL
  const checkIsAutreProcedure = (procedure_id) => {
    let response = checkIsAutreProcedureHelper(procedure_id, procedures);
    if (response) {
      setIsAutreProcedure(true);
      return true;
    } else {
      setIsAutreProcedure(false);
      return false;
    }
  };

  const initialFormData = {
    currentDate: getFormattedDate(),
    objet_id: "",
    jugement_attaque: "",
    date_jugement: "",
    provenance: "",
    type_procedure_id: "",
    get_reference_dossier: "",
    calendrier_id: "",
    procedure_id: "",
    // Ajoutez d'autres champs ici selon vos besoins
  };

  const viderFormulaire = () => {
    dispatch(resetDossierFormulaire());
    setAddFormData(initialFormData);
    setPaths([]);
    //setTypedocument_id('')
    setModePaiementId("");
    setCalendrier_id("");
    setObjet_id("");
    setProcedures([]);

    afficherMessagesSuccess("Formulaire vidé avec succès");    
  };

  //get liste tribunaux
  const fetchTribunaux = async () => {
    try {
      const response = await TribunauxService.index(token);
      const responseData = response.data;

      if (
        responseData.status &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        const data = responseData.data.map((item) => {
          return {
            name: item.libelle,
            id: item.id,
          };
        });
        setTribunaux(data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête : ", error);
    }
  };

  //Filtrer les type document selon la procedure sélectionnée
  const checkTypedocumentCanReturn = (data) => {
    if (
      findLibelleProcedure(addFormData.procedure_id) === "AVENIR D'AUDIENCE"
    ) {
      const findData = data.filter(
        (type) =>
          type.libelle.startsWith("Autre") ||
          type.libelle.startsWith("Acte d'appel")
      );
      return findData;
    }
    return data;
  };

  //Les avocats/acteurs de justice
  const fetchAssujetties_acteurs = async () => {
    try {
      const response = await AssujettiesService.index_acteurs(token);

      //Get only Avocat
      const filteredData = response.data.data.filter(
        (item) => item.get_type_acteur?.libelle === "Avocat"
      );

      setAssujetties_acteurs(filteredData);
      //console.log(filteredData)
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowConfirmModal = () => {
    fetchAssujetties_acteurs();
    setShowModal(true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: { addFormData, showNumeroDossier },
  });

  const [selectedPdf, setSelectedPdf] = useState(null);
  const [showModalPDF, setShowModalPDF] = useState(false);

  const handleViewPdf = (file) => {
    if (file.type === "application/pdf") {
      setSelectedPdf(URL.createObjectURL(file));
      setShowModalPDF(true);
    }
  };

  const handleCloseModalPDF = () => {
    setSelectedPdf(null);
    setShowModalPDF(false);
  };

  const handleDownloadFile = (file) => {
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = file.name;
    link.click();
  };

  useEffect(() => {
    setCurrentDate(getFormattedDate());
    fetchObjets();
    fetchCalendriers();
    fetchProcedures();
    fetchTypesdocuments();
    fetchModepaiements();
    fetchTypesprocedures();
    fetchTribunaux();
    addKkiapayListener("success", successHandler);
    return () => {
      removeKkiapayListener("success", successHandler);
    };
  }, []);

  return (
    <LayoutActeur>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <Form>
            <div className="container-xl app-card-body">
              <div className="row g-3 align-items-center justify-content-between">
                <div className="col-auto">
                  <div className="pagetitle">
                    <h1 className="app-page-title mb-0">
                      Gestion des dossiers / NOUVELLE SAISINE
                    </h1>
                    <nav>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to={myroutes.dashboard_acteur}>Accueil</Link>
                        </li>
                        <li className="breadcrumb-item active">
                          <Link to={myroutes.dossiersacteurs_index}>
                            Dossiers
                          </Link>
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>

              <div className="row g-3 mb-0  align-items-center justify-content-between">
                <div
                  className="card bg-successx p-2"
                  style={{
                    color: "#264f52",
                    backgroundColor: "white",
                    border: "1px solid white",
                  }}
                >
                  <h4>
                    <i className="bi bi-folder"></i> Enrôlement d'un nouveau
                    dossier
                  </h4>
                </div>
                <p className="text-danger h6 px-3">
                  Pour procéder à l’enrôlement, veuillez cliquer sur chaque
                  étape ci-dessous pour afficher et renseigner les champs
                  requis. A chaque état, merci de cliquer sur le bouton{" "}
                  <b>Valider</b> pour sauvegarder vos saisies.
                </p>
              </div>

              <div className="card">
                <div className="accordion" id="accordionExample">
                  {/** Renseignements sur l'affaire */}

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button custom-header"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        <span className="fw-bold">
                          {" "}
                          1. RENSEIGNEMENTS SUR L'AFFAIRE
                        </span>
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse showxx"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          {validationsErrors.length > 0 && (
                            <div className="col-12">
                              <div className="alert alert-danger">
                                <ul>
                                  {validationsErrors.map((error, index) => (
                                    <li key={index}>{error}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="row">
                          <div className="col-4">
                            <Form.Label className="fw-bold label-form">
                              Date de la saisine{" "}
                              <i
                                className="text-info bi bi-question-circle-fill"
                                title="Date à laquelle la saisine est éditée"
                              ></i>
                            </Form.Label>{" "}
                            <br />
                            <Form.Label className="fw-bold label-form">
                              Objet de la saisine{" "}
                              <i className="text-danger">*</i>{" "}
                              <i
                                className="text-info bi bi-question-circle-fill"
                                title="Objet de la saisine"
                              ></i>
                            </Form.Label>{" "}
                            <br />
                            <Form.Label className="fw-bold label-form">
                              Jugement attaqué, Date et Provenance{" "}
                              <i className="text-danger">*</i>{" "}
                              <i
                                className="text-info bi bi-question-circle-fill"
                                title="Objet de la saisine"
                              ></i>
                            </Form.Label>{" "}
                            <br />
                            <Form.Label className="fw-bold label-form">
                              Type de Procédure <i className="text-danger">*</i>{" "}
                              <i
                                className="text-info bi bi-question-circle-fill"
                                title="Procédure choisie"
                              ></i>
                            </Form.Label>{" "}
                            <br />
                            {addFormData.type_procedure_id && (
                              <>
                                <Form.Label className="fw-bold label-form">
                                  {addFormData.type_procedure_id != 3
                                    ? "Procédure"
                                    : "Référence dossier"}
                                  <i className="text-danger">*</i>{" "}
                                  <i
                                    className="text-info bi bi-question-circle-fill"
                                    title="Procédure choisie"
                                  ></i>
                                </Form.Label>{" "}
                                <br />
                              </>
                            )}
                            {showNumeroDossier && (
                              <>
                                <Form.Label className="fw-bold label-form">
                                  Référence du dossier/ordonnance{" "}
                                  <i className="text-danger">*</i>{" "}
                                  <i
                                    className="text-info bi bi-question-circle-fill"
                                    title="Référence du dossier/ordonnance"
                                  ></i>
                                </Form.Label>{" "}
                                <br />
                              </>
                            )}
                            <Form.Label className="fw-bold">
                              Date d'audience <i className="text-danger">*</i>{" "}
                              <i
                                className="text-info bi bi-question-circle-fill"
                                title="Date à laquelle votre audience aura lieu"
                              ></i>
                            </Form.Label>{" "}
                            <br />
                          </div>
                          <div className="col-8">
                            <Form.Control
                              className="border border rounded-2 control-label"
                              placeholder=""
                              name="date_saisine"
                              aria-label="date_saisine"
                              maxLength={100}
                              minLength={3}
                              type="date"
                              required
                              value={currentDate}
                              onChange={(e) => setCurrentDate(e.target.value)}
                              disabled
                            />

                            <Form.Select
                              {...register("objet_id")}
                              className={`control-label ${
                                errors.objet_id ? "is-invalid" : ""
                              }`}
                              id="objet_id"
                              name="objet_id"
                              value={addFormData.objet_id}
                              onChange={handleChange}
                              required
                            >
                              <option value="" selected>
                                -- Sélectionnez dans la liste --
                              </option>
                              {objets &&
                                objets.length > 0 &&
                                objets.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.libelle}
                                  </option>
                                ))}
                            </Form.Select>
                            <div className="invalid-feedback">
                              {errors.objet_id?.message}
                            </div>

                            <div className="row">
                              <div className="col-md-4">
                                <Form.Control
                                  {...register("jugement_attaque")}
                                  className={`border border rounded-2 control-label ${
                                    errors.jugement_attaque ? "is-invalid" : ""
                                  }`}
                                  placeholder="Référence décision"
                                  name="jugement_attaque"
                                  aria-label="jugement_attaque"
                                  maxLength={100}
                                  minLength={3}
                                  type="text"
                                  value={addFormData.jugement_attaque}
                                  onChange={handleChange}
                                />
                                <div className="invalid-feedback">
                                  {errors.jugement_attaque?.message}
                                </div>
                              </div>
                              <div className="col-md-3">
                                <Form.Control
                                  {...register("date_jugement")}
                                  className={`border border rounded-2 control-label ${
                                    errors.date_jugement ? "is-invalid" : ""
                                  }`}
                                  placeholder="Date Jugement"
                                  name="date_jugement"
                                  aria-label="date_jugement"
                                  maxLength={100}
                                  minLength={3}
                                  type="date"
                                  onChange={handleChange}
                                  value={addFormData.date_jugement}
                                />
                                <div className="invalid-feedback">
                                  {errors.date_jugement?.message}
                                </div>
                              </div>
                              <div className="col-md-5">
                                <Dropdown
                                  {...register("provenance")}
                                  value={addFormData.provenance}
                                  id="provenance"
                                  required
                                  name="provenance"
                                  onChange={handleChange}
                                  options={tribunaux}
                                  optionLabel="name"
                                  placeholder="Tribunaux..."
                                  filter
                                  className={`w-100 h-50 ${
                                    errors.provenance ? "is-invalid" : ""
                                  }`}
                                  checkmark={true}
                                />
                                <div className="invalid-feedback">
                                  {errors.provenance?.message}
                                </div>
                              </div>
                            </div>

                            <div className="row mb-4 pt-2">
                              <div className="col-md-6">
                                <div className="p-field">
                                  <RadioButton
                                    className="me-2"
                                    inputId="PF"
                                    name="PF"
                                    value={1}
                                    onChange={handleChangeTypeprocedure}
                                    checked={
                                      addFormData.type_procedure_id === 1
                                    }
                                  />
                                  <label htmlFor="PF" className="fw-bold">
                                    PROCEDURE AU FOND
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="p-field">
                                  <RadioButton
                                    className="me-2"
                                    inputId="PP"
                                    name="PP"
                                    value={2}
                                    onChange={handleChangeTypeprocedure}
                                    checked={
                                      addFormData.type_procedure_id === 2
                                    }
                                  />
                                  <label htmlFor="PP" className="fw-bold">
                                    PROCEDURE PRESIDENTIELLE
                                  </label>
                                </div>
                              </div>

                              {errors.type_procedure_id && (
                                <p className="text-danger">
                                  {errors.type_procedure_id.message}
                                  {addFormData.type_procedure_id}
                                </p>
                              )}
                              {/**
                                 * <div className="col-md-3">
                                 <div className="p-field">
                                 <RadioButton className="me-2" inputId="DEP" name="DEP" value='3' onChange={handleChangeTypeprocedure} checked={addFormData.type_procedure_id === 3} />
                                 <label htmlFor="DEP" className="fw-bold">PROCEDURE DEP</label>
                                 </div>
                                 </div>
                                 */}
                            </div>
                            <div className="row mb-2">
                              {procedures.map((option, index) => (
                                <div className="col-md-6 py-1" key={index}>
                                  <div className="p-field">
                                    <RadioButton
                                      {...register("procedure_id")}
                                      className="me-2"
                                      inputId={`option-${index}`}
                                      name="procedure_id"
                                      value={option.id}
                                      onChange={handleChange}
                                      checked={
                                        addFormData.procedure_id == option.id
                                      }
                                    />
                                    <label
                                      htmlFor={`option-${index}`}
                                      className="fw-bold"
                                    >
                                      {option.libelle}
                                    </label>
                                  </div>
                                </div>
                              ))}

                              {showNumeroDossier && (
                                <div className="col-md-12 mt-4">
                                  <InputGroup className="">
                                    <Form.Control
                                      className="border border rounded-2 control-label"
                                      placeholder="Référence du dossier/ordonnance"
                                      name="reference_dossier"
                                      aria-label="reference_dossier"
                                      maxLength={100}
                                      minLength={3}
                                      type="text"
                                      value={addFormData.reference_dossier}
                                      onChange={handleChange}
                                    />
                                    {/**<InputGroup.Text id="basic-addon2" style={{ height: "30px" }} className="text-bg-success" onClick={() => handleFindDatesAudiences()}>OK</InputGroup.Text> */}
                                  </InputGroup>
                                </div>
                              )}
                            </div>

                            <Form.Select
                              className={`${
                                showNumeroDossier
                                  ? "control-label mt-2"
                                  : "control-label mt-4"
                              }`}
                              id="calendrier_id"
                              name="calendrier_id"
                              value={addFormData.calendrier_id}
                              onChange={handleChange}
                              required
                            >
                              <option value="" selected>
                                -- Sélectionnez la date --
                              </option>
                              {calendriers &&
                                calendriers.length > 0 &&
                                calendriers.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {convertDateToTexte(item.jour)}
                                  </option>
                                ))}
                            </Form.Select>
                          </div>

                          <div>
                            <Button
                              className="mb-3 w-100"
                              variant="success"
                              disabled={isSubmitting}
                              onClick={() => handleSaveProgess()}
                              style={{ float: "right" }}
                            >
                              Valider
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/** End Renseignements sur l'affaire */}

                  {/** Appelantes */}

                  {addFormData.type_procedure_id != 3 && (
                    <>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed custom-header"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            2. APPELANT-E-S
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Vous pouvez ajouter plusieurs{" "}
                              <strong>Appelant-e-s</strong> en cliquant sur le
                              bouton <strong>Ajouter plus</strong> en bas du
                              formulaire
                            </p>
                            <AppelanteFormContainer />
                          </div>
                        </div>
                      </div>

                      {/**End  Appelantes */}

                      {/** Intimées */}

                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed custom-header"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            3. INTIME-E-S
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Vous pouvez ajouter plusieurs{" "}
                              <strong>Intimé-e-s</strong> en cliquant sur le
                              bouton <strong>Ajouter plus</strong> en bas du
                              formulaire
                            </p>
                            <IntimeFormContainer />
                          </div>
                        </div>
                      </div>
                      {/**End  Intimées */}
                    </>
                  )}

                  {/**Acte de saisine */}

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed custom-header"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        {addFormData.type_procedure_id != 3 ? "4." : "2."}{" "}
                        ACTE(S) DE VOTRE SAISINE
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p className="mb-2" style={{ fontSize: "16px" }}>
                          Ajouter le(s) document(s) nécessaires pour votre
                          saisine et adapté(s) à votre type de procédure.
                        </p>
                        {findLibelleProcedure(addFormData.procedure_id) ===
                          "AVENIR D'AUDIENCE" && (
                          <strong
                            className=""
                            style={{ fontStyle: "italicx", color: "red" }}
                          >
                            NB : L'acte d'appel autorisant la procédure est
                            obligatoire pour la procédure AVENIR D'AUDIENCE
                          </strong>
                        )}
                        <p
                          className="mt-4"
                          style={{ fontSize: "16px", fontWeight: "bold" }}
                        >
                          {" "}
                          Fichiers acceptés (PDF et WORD)
                        </p>

                        {/**Start bloc */}

                        {docData.map((doc, index) => (
                          <div key={doc.id}>
                            <Row>
                              <Col md={6}>
                                <label htmlFor="" className="fw-bold">
                                  Type de documents{" "}
                                  <i className="text-danger">*</i>
                                </label>
                                <Form.Select
                                  className="control-label form-control-lg"
                                  id="type_document_id"
                                  name={`type_document_id-${doc.id}`}
                                  value={doc.type_document_id}
                                  onChange={(e) =>
                                    handleChangeTypeDoc(e, doc.id)
                                  }
                                  style={{
                                    padding: "10px",
                                    border: "2px solid #ccc",
                                    borderRadius: "5px",
                                    backgroundColor: "#f8f9fa",
                                    color: "#495057",
                                  }}
                                >
                                  <option value="" disabled>
                                    -- Sélectionnez dans la liste --
                                  </option>
                                  {typesdocuments &&
                                    checkTypedocumentCanReturn(typesdocuments)
                                      .length > 0 &&
                                    checkTypedocumentCanReturn(
                                      typesdocuments
                                    ).map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.libelle}
                                      </option>
                                    ))}
                                </Form.Select>
                              </Col>
                              <Col md={6} sm={12} className="form-group">
                                <label htmlFor="">
                                  Fichier <i className="text-danger">*</i>
                                </label>
                                <Form.Group className="mb-3">
                                  <Form.Control
                                    className="form-control-file"
                                    style={{
                                      border: "2px solid #ccc",
                                      borderRadius: "5px",
                                      backgroundColor: "#f8f9fa",
                                      color: "#495057",
                                    }}
                                    onChange={(e) =>
                                      handleFileChange(e, doc.id)
                                    }
                                    name="path"
                                    aria-label="path"
                                    required
                                    type="file"
                                    accept=".pdf, .doc, .docx, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    id="path"
                                    multiple={false}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            {index > 0 && (
                              <Button
                                className="mb-3 me-3"
                                variant="danger"
                                onClick={() => removeDocument(doc.id)}
                              >
                                Supprimer
                              </Button>
                            )}
                            {index === docData.length - 1 && ( // Afficher les boutons uniquement pour le dernier bloc
                              <>
                                <Button
                                  className="mb-3"
                                  type="button"
                                  variant="success me-3"
                                  onClick={addDocument}
                                >
                                  <i className="bi bi-plus"></i> Ajouter plus
                                </Button>
                              </>
                            )}
                          </div>
                        ))}
                        {/*<div className="border-success-subtle border-top py-2">
                          {docData.map(
                            (item, index) =>
                              item.file && (
                                <div key={index}>
                                  <p>Nom du fichier : {item.file.name}</p>
                                </div>
                              )
                          )}
                        </div>*/}

                        <div className="border-success-subtle border-top py-3">
                          {docData.map(
                            (item, index) =>
                              item.file && (
                                <div
                                  key={index}
                                  className="d-flex align-items-center justify-content-between mb-3 p-2"
                                  style={{
                                    border: "1px solid #eaeaea",
                                    borderRadius: "8px",
                                    backgroundColor: "#f9f9f9",
                                  }}
                                >
                                  {/* Section gauche : Icône et nom */}
                                  <div className="d-flex align-items-center">
                                    {/* Vérification du type de fichier pour afficher l'icône appropriée */}
                                    <img
                                      src={
                                        item.file.type === "application/pdf"
                                          ? "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                                          : "/word.png"
                                        }
                                      alt="File Icon"
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        marginRight: "15px",
                                      }}
                                    />
                                    {/* Nom du fichier */}
                                    <p
                                      style={{
                                        margin: 0,
                                        fontWeight: "bold",
                                        color: "#333",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      {item.file.name}
                                    </p>
                                  </div>

                                  {/* Section droite : Bouton */}
                                  {item.file.type === "application/pdf" ? (
                                    <button
                                    type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() => handleViewPdf(item.file)}
                                      style={{
                                        fontSize: "0.875rem",
                                        padding: "5px 10px",
                                      }}
                                    >
                                      Voir le fichier
                                    </button>
                                  ) : (
                                    <button
                                    type="button"
                                      className="btn btn-secondary btn-sm"
                                      onClick={() =>
                                        handleDownloadFile(item.file)
                                      }
                                      style={{
                                        fontSize: "0.875rem",
                                        padding: "5px 10px",
                                      }}
                                    >
                                      Télécharger
                                    </button>
                                  )}
                                </div>
                              )
                          )}
                        </div>

                        {/* Modal pour afficher le PDF */}
                        {showModalPDF && (
                          <div
                            className="modal-overlay"
                            style={{
                              position: "fixed",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              zIndex: 1000,
                            }}
                          >
                            <div
                              className="modal-content"
                              style={{
                                backgroundColor: "#fff",
                                width: "80%",
                                height: "80%",
                                borderRadius: "8px",
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              <button
                                type="button"
                                onClick={() => handleCloseModalPDF()}
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  backgroundColor: "#f44336",
                                  color: "#fff",
                                  border: "none",
                                  borderRadius: "50%",
                                  width: "35px",
                                  height: "35px",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
                                }}
                              >
                                &times;
                              </button>
                              <embed
                                src={selectedPdf}
                                type="application/pdf"
                                width="100%"
                                height="100%"
                              />
                            </div>
                          </div>
                        )}

                        {/**End bloc */}
                      </div>
                    </div>
                  </div>
                  {/**End Acte de saisine */}

                  {/**Mode de paiement */}
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed custom-header"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        {addFormData.type_procedure_id != 3 ? "5." : "3."} MODE
                        DE PAIEMENT
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p style={{ textAlign: "justify", fontSize: "16px" }}>
                          <div className="mb-3">
                            <strong className="mb-4 me-3">
                              Mode de paiements disponibles
                            </strong>
                          </div>
                          <div className="row mb-4 pt-2">
                            {modespaiements.length > 0 &&
                              modespaiements.map((item) => (
                                <div className="col-md-4">
                                  <div className="p-field">
                                    <RadioButton
                                      className="me-2"
                                      inputId={item.code}
                                      value={item.code}
                                      onChange={(e) =>
                                        setModePaiementId(e.target.value)
                                      }
                                      checked={modepaiement_id === item.code}
                                    />
                                    <label
                                      htmlFor={item.code}
                                      className="fw-bold"
                                    >
                                      {item.libelle}
                                    </label>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>

                  {/**End Mode de paiement */}

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed custom-header"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        {addFormData.type_procedure_id != 3 ? "6." : "4."}{" "}
                        RECAPITULATIF
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p style={{ textAlign: "justify", fontSize: "16px" }}>
                          <div className="mb-3">
                            <strong className="mb-4 me-3">
                              Récapitulatif des opérations et tarifs en CFA:
                            </strong>
                            <span
                              className="badge rounded-pill text-bg-secondary"
                              style={{ textAlign: "justify", fontSize: "18px" }}
                            >
                              10 000 FCFA
                            </span>
                            <br />
                          </div>
                          <ul>
                            <li>
                              Veuillez bien vérifier vos données avant de payer
                              et soumettre le dossier
                            </li>
                            <br />
                            <li>
                              Faites attention à bien remplir les champs
                              obligatoires afin d'activer le bouton de paiement.
                            </li>
                            <br />
                            <li>
                              En cliquant sur{" "}
                              <strong>Vérifier les données</strong> , vous
                              pourrez procéder au paiement par Mobile Money
                              (MOMO) ou carte bancaire, puis votre demande sera
                              automatiquement envoyée une fois le paiement
                              validé.
                            </li>
                            <br />
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ textAlign: "right" }}>
              <button
                type="button"
                className="btn btn-secondary me-3"
                onClick={() => viderFormulaire()}
              >
                Vider le formulaire
              </button>
              {dataIsVerified && (
                <button
                  type="button"
                  className="btn btn-warning me-3 fw-boldx"
                  onClick={() => handleShowConfirmModal()}
                >
                  Vérifier les données
                </button>
              )}
            </div>
          </Form>
        </div>

        {/* Modal de confirmation */}
        <ConfirmationModal
          visible={showModal}
          onHide={handleCancelEnregistrement}
          onConfirm={handleConfirmEnregistrement}
          types_procedures={all_types_procedures}
          addFormData={addFormData}
          objets={objets}
          procedures={procedures}
          allCalendriers={allCalendriers}
          docData={docData}
          libelleTribunal={libelleTribunal}
          assujetties_acteurs={assujetties_acteurs}
        />
      </main>

      <Modal show={loading} backdrop="static" keyboard={false} centered>
        <Modal.Body className="text-center">
          <ProgressSpinner />
          <p className="my-3 fw-bold" style={{ fontSize: "18px" }}>
            Veuillez patienter pendant l'enregistrement de vos données...
          </p>
        </Modal.Body>
      </Modal>
    </LayoutActeur>
  );
}
